@import './fonts.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply Assistant as the default font */
body {
  font-family: 'Assistant', sans-serif;
  background-color: #f4f3ee;
}

/* Apply Heebo font to English text */
:lang(en) {
  font-family: 'Heebo', sans-serif;
}

/* Apply antialiasing and reset margins */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroll-down-arrow {
  background: none;
  border: none;
  font-size: 2rem;
  color: #ffffff;
  cursor: pointer;
  animation: bounce 2s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5); /* Added for better visibility */
  border-radius: 50%;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.custom-drawer .ant-drawer-header.ant-drawer-header-close-only {
  width: 100% !important;
  position: relative;
}
.custom-drawer .ant-drawer-close {
  position: absolute;
  top: 16px;
  right: 16px;
  left: auto !important;
  transform: none !important;
}

.custom-arrow {
  font-size: 36px !important;
  font-weight: bold !important;
  color: white !important;
  z-index: 1 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.custom-arrow.slick-prev {
  left: 10px !important;
}

.custom-arrow.slick-next {
  right: 10px !important;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-carousel .slick-prev::after,
:where(.css-dev-only-do-not-override-1uq9j6g).ant-carousel .slick-next::after {
  display: none;
}

.backgroundShadowFull {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #6b9080;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

.fade-in-section .fade-in {
  animation-delay: 0.5s;
}

.fade-in-section .fade-in:nth-child(2) {
  animation-delay: 0.7s;
}

.fade-in-section .fade-in:nth-child(3) {
  animation-delay: 0.9s;
}

.fade-in-section .fade-in:nth-child(4) {
  animation-delay: 1.1s;
}

.fade-in-section .fade-in:nth-child(5) {
  animation-delay: 1.3s;
}

@keyframes fadeInLogo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in-scale-up-logo {
  animation: fadeInLogo 2.5s ease-out forwards;
}
